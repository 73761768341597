const easingOutQuint = (x, t, b, c, d) => c * ((t = t / d - 1) * t * t * t * t + 1) + b;

export const smoothScrollPolyfill = (node, value) => {
	let startTime = null;
	const offset = node.scrollTop;
	const gap = value - offset;
	const duration = 1000;

	const step = (t) => {
		if (!startTime) {
			startTime = t;
		}
		const elapsed = t - startTime;
		const percentage = elapsed / duration;

		if (percentage > 1) {
			return;
		}
		node.scrollTop = easingOutQuint(0, elapsed, offset, gap, duration);
		requestAnimationFrame(step);
	};

	step();
};

