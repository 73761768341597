import {smoothScrollPolyfill} from './scroll';

export default () => {
	let isTablet = false;
	const TabletWidth = 1024;
	const navButton = document.querySelector('.nav__toggle');
	const navMain = document.querySelector('.nav');
	const invertExpanded = (el) => el.getAttribute('aria-expanded') === 'false' ? 'true' : 'false';
	if (!navButton || !navMain) {
		return;
	}

	document.addEventListener('click', (e) => {
		if (isTablet) {
			if (!e.target.closest('.nav__toggle')) {
				navButton.setAttribute('aria-expanded', 'false');
				navMain.classList.remove('nav--menu-active');
			} else {
				e.preventDefault();
				navButton.setAttribute('aria-expanded', invertExpanded(navButton));
				navMain.classList.toggle('nav--menu-active');
			}
		}

		const menuLink = e.target.closest('.target-link');
		if (menuLink) {
			const hasNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
			if (!hasNativeSmoothScroll) {
				e.preventDefault();
				const href = menuLink.getAttribute('href');
				const targetId = href.substring(1);
				const target = document.getElementById(targetId);
				if (target) {
					const offset = target.getBoundingClientRect().top;
					smoothScrollPolyfill(document.documentElement, offset);
				}
			}
		}
	});

	if (document.documentElement.clientWidth < TabletWidth) {
		isTablet = true;
	}
	window.addEventListener('resize', () => {
		if (document.documentElement.clientWidth > TabletWidth && isTablet) {
			navMain.classList.remove('nav--menu-active');
			navButton.setAttribute('aria-expanded', 'false');
			isTablet = false;
			return;
		}
		if (document.documentElement.clientWidth < TabletWidth) {
			isTablet = true;
		}
	});
};

